.background-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  
  .background-upload {
    border: 2px dashed #3498db;
    background-color: #f5f5f5;
    color: #3498db;
    padding: 60px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 10px;
    max-height: 150px;
    width: 100%;
  }
  
  .background-upload.active {
    background-color: #3498db;
    color: #fff;
  }
  
  .background-upload p {
    margin: 0;
  }
  
  .background-upload input {
    display: none;
  }
  
  .background-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .background-picture img {
    border-radius: 10px;
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
  }
  
  .background-picture button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .background-picture button:hover {
    background-color: #2980b9;
  }
  
  .background-upload-container {
    text-align: center;
  }
  