.Tree {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    margin: 0;
    overflow: hidden; /* To prevent horizontal scrollbars */
    padding-top: 50px;
    min-height: 100vh;
  }
  
  .profile-container {
    text-align: center;
    padding: 40px;
    border-radius: 10px;
    width: 100%; /* Adjusted to take up full viewport width */
    max-width: 400px; /* Optional: You can limit the maximum width if needed */
  }
  
  .footer {
    text-align: center;
    color: #fff;
    font-size: 14px;
    position: fixed;
      bottom: 0;
      left: 0;
      font-family: 'Cairo';
      width: 100%;
      background-color: #F6FFA6;
  
  }
  .footer-text {
    color:#fff !important ;
    text-decoration:none;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Cairo';
  
  }
  .link{
    color:#372561 !important ;
    text-decoration:none;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
  }
  .profile-pic {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
  }
  .circle {
    border-radius: 50%;

  }
  .profile-title {
    font-weight: bold;
    margin: 10px 0;
    color: #ffffff;
    font-family: 'Cairo';
  
  }
  
  .profile-subtitle {
    margin: 5px 0;
    color: #ffffff;
    font-family: 'Cairo';
  
  }
  
  .profile-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .profile-button {
    text-decoration: none;
    width: 100%; /* Adjusted to make the buttons almost fill the screen width */
    max-width: 300px; /* Optional: You can limit the maximum width if needed */
    padding: 10px;
    margin: 10px;
    background-color: #f0f0f0; /* Set the background color to white */
    color: #000; /* Set the text color to black */
    border: none;
    border-radius: 50px;
    font-size:larger;
    font-weight:500;
    font-family: 'Cairo';
  
  }
  .social-media-icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .social-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }

  ::-webkit-scrollbar {display:none;}
