/* Style for Buttons */
.array-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .array-item-label {
    font-weight: bold;
  }
  
  .array-item-input {
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  /* Style for Social Media */
  .mb-3.social-media-item {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .social-media-item label {
    font-weight: bold;
  }
  
  .social-media-item .form-control {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
  }
  
  /* Style for Add Button and Add Social Media Button */
  .btn-primary.add-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    margin-top: 10px;
  }
  
  .btn-primary.add-button:hover {
    background-color: #0056b3;
  }
  
  /* Style for Submit Button */
  .btn-success.submit-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    margin-top: 15px;
  }
  
  .btn-success.submit-button:hover {
    background-color: #1e7e34;
  }
  