
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-color: #004EBF;
}

.login-box {
  font-family: 'Cairo', sans-serif;
  text-align: right;
  direction: rtl;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.login-logo {
    max-width: 200px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    display: block;
}

.login-header {
  font-size: 24px;
  color: #004EBF;
}

.form-group {
  margin: 10px 0;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.text-center a {
  text-decoration: none;
  color: #004EBF;
}

.text-center a:hover {
  text-decoration: underline;
}
.btn-primary{

    background-color: #004EBF   ;
}
